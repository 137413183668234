import React, { useContext, useState, useEffect } from "react";
import { BottomBar } from "./BottomBar";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import { AnimatedHint } from "./AnimatedHint";

const sleep = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
);

interface GenericHintProps {
    imgSrc: string;
    nextRoute: string;
    doText: string;
    dontText: string;
    waitTime?: number;
}

export const GenericHint: React.FC<GenericHintProps> = ({ imgSrc, nextRoute, doText, dontText, waitTime = 4000 }) => {
    const language = useContext(LanguageContext);
    const navigate = useNavigate();
    const searchParams = window.location.search;
    const [canContinue, setCanContinue] = useState(false);
    const [clickCount, setClickCount] = useState(0);

    useEffect(() => {
        async function load() {
            await sleep(waitTime);
            setCanContinue(true);
        }
        load();
    }, [waitTime]);

    const onClick = () => {
        setClickCount(prevCount => prevCount + 1);
        if (canContinue || clickCount > 3) {
            navigate(`${nextRoute}${searchParams}`);
        }
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-grow overflow-y-auto">
                <AnimatedHint imgSrc={imgSrc} do_text={doText} dont_text={dontText} />
            </div>
            <div onClick={onClick}>
                <BottomBar text={canContinue ? language.hints_next_button : language.hints_next_button_waiting} inactivate={!canContinue} />
            </div>
        </div>
    );
}