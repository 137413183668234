// React login page
// when one enters username and password, it will be sent to the backend

import React, { useEffect, useState } from "react";

import { supabase } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import { UserSessionContext } from "./App";
import { Spinner } from "./components/Spinner";


export function LoginAnon() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const { setUserSession } = React.useContext(UserSessionContext);

  useEffect(() => {
    handleSession();
    pseudoLogin();
  }, []);

  const handleSession = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      setError(error.message);
      return;
    }
    if (data.session) {
      setUserSession(data.session);
      setLoggedIn(true);
      navigate("/landing");
    }
  };

  async function pseudoLogin(event?: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    const randomEmail = `anon_${Math.random().toString(36).substring(7)}@selfideed.com`;
    const randomPassword = Math.random().toString(36).substring(2);
    const { data, error } = await supabase.auth.signUp({
      email: randomEmail,
      password: randomPassword,
    })
    if (error?.message) {
      setError(error.message);
    } else {
      const access_token = data.session!.access_token;
      const refresh_token = data.session!.refresh_token;
      await supabase.auth.setSession({ access_token, refresh_token });
      setLoggedIn(true);
      handleSession();
    }
  }

  return (
    <>
      {loggedIn || (
        <div className="flex flex-col items-center">
        <div className="flex justify-center">
          <form
            onSubmit={pseudoLogin}
            className="bg-white rounded px-8 pt-6 pb-8 mb-4"
          >
            <div className="flex flex-col items-center ">
              <Spinner />
            </div>
          </form>
        </div>
          <p className="text-red-500 font-bold">{error}</p>
        </div>
      )}
    </>
  );
}
