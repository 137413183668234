import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import { CountryContext } from "../contexts/CountryContext";

export const CountrySelected = () => {
    const language = useContext(LanguageContext);
    const navigate = useNavigate();
    const { country } = useContext(CountryContext);
    const mapCountryToMap = (country) => {
        switch (country) {
            case "uk":
                return "gb";
            default:
                return country;
        }
    };
    return (
        <>
            {country && (
                <div className="mb-4 flex flex-col items-center cursor-pointer">
                    <span className={"!w-8 h-8 fi fi-" + mapCountryToMap(country)}></span>
                    <a className="mt-2 text-lg font-semibold px-4 py-2 rounded-lg bg-gray-100 underline text-primary text-sm" onClick={() => navigate("/countries")}>{language.country_options_choose_lang}</a>
                </div>
            )}
        </>
    )
}