import React from "react";

export const Spinner = () => {
  return (
    <svg
      className="animate-spin"
      width="94"
      height="94"
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M94 47C94 56.2957 91.2435 65.3827 86.0791 73.1118C80.9146 80.8409 73.5742 86.865 64.9861 90.4223C56.398 93.9797 46.9479 94.9104 37.8307 93.0969C28.7136 91.2834 20.339 86.8071 13.766 80.234C7.19291 73.6609 2.71659 65.2863 0.903089 56.1692C-0.910414 47.0521 0.0203463 37.602 3.57767 29.0139C7.13499 20.4257 13.1591 13.0853 20.8882 7.92092C28.6173 2.75649 37.7043 -4.54339e-06 47 0L47 8.0187C39.2902 8.0187 31.7536 10.3049 25.3432 14.5882C18.9327 18.8715 13.9364 24.9596 10.986 32.0825C8.03558 39.2054 7.26362 47.0432 8.76772 54.6049C10.2718 62.1665 13.9844 69.1123 19.4361 74.5639C24.8877 80.0156 31.8335 83.7282 39.3951 85.2323C46.9568 86.7364 54.7946 85.9644 61.9175 83.014C69.0404 80.0636 75.1284 75.0673 79.4118 68.6568C83.6951 62.2464 85.9813 54.7098 85.9813 47H94Z"
        fill="#4A92D2"
      />
    </svg>
  );
};
