import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";

const photo_animation = new URL("../../img/ID.landingpage.gif", import.meta.url);

interface StartCardProps {
  flickerIndex: number;
  imageLoaded: boolean;
  onImageLoad: () => void;
}

export const StartCard: React.FC<StartCardProps> = ({ flickerIndex, imageLoaded, onImageLoad }) => {
  const language = useContext(LanguageContext);

  const flickerClasses = [
    "bg-blue-100",
    "bg-blue-200",
    "bg-blue-300",
    "bg-blue-400",
  ];

  return (
    <div className="flex flex-col items-center">
      <img
        src={photo_animation.toString()}
        className="object-contain drop-shadow-lg h-64 w-64"
        alt="ID Animation"
        onLoad={onImageLoad}
      />
      <div className="mt-4 text-center">
        <p className={`text-lg font-semibold px-4 py-2 rounded-lg ${imageLoaded ? flickerClasses[flickerIndex] : 'bg-gray-100'}`}>
          {language.worldwide}
        </p>
      </div>
    </div>
  );
};