import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReviewPopup } from "./components/ReviewPopup";
import { ThankYouConfirmation } from "./components/ThankYouConfirmation";

export const Review = () => {
  const navigate = useNavigate();
  let [urlSearchParams] = useSearchParams();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = () => {
    setShowSuccess(true);
  };


  return (
    <>
      {showSuccess && (
        <ThankYouConfirmation
          isOpen={true}
          onClose={() => { setShowSuccess(false) }}
          isFullScreen={true}
        />
      ) || (
          <ReviewPopup
            isOpen={true}
            onClose={() => { }}
            onSubmit={handleSubmit}
            fullScreen={true}
          />
        )}
    </>
  );
};
