import React, { useContext } from "react";
import { LanguageContext } from "./contexts/LanguageContext";
import { GenericHint } from "./components/GenericHint";

const imgRules = new URL("../img/ID.manual-3.gif", import.meta.url);

export const Hints3 = () => {
    const language = useContext(LanguageContext);

    return (
        <GenericHint
            imgSrc={imgRules.toString()}
            nextRoute="/hints4"
            doText={language.hints3_do_text}
            dontText={language.hints3_dont_text}
        />
    );
}