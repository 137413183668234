import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { Spinner } from "./micro/spinner";

type Props = {
  uploadProgress: number;
};

export const UploadCardSpinner = (props: Props) => {
  const { uploadProgress } = props;
  const language = useContext(LanguageContext);
  return (
    <>
      <div className="bg-secondary border border-dashed border-primary rounded-3xl flex flex-col justify-center items-center h-72 w-72">
        <span className="text-primary h-20 mb-10">
          <Spinner></Spinner>
        </span>
        <h2 className="text-xl font-bold mb-2 text-primary">{language.processing_title}</h2>
        {uploadProgress < 100 ? (
          <>
            <div className="text-mid animate-pulse">
              Uploading {uploadProgress}%
            </div>
          </>
        ) : (
          <>
            <div className="text-mid animate-pulse">
              {language.processing_hint}
            </div>
          </>
        )}
      </div>
    </>
  );
};
