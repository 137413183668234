import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { translations } from "../lang/texts";
import { CheckMark } from "./CheckMark";
import {
  Badge,
  CheckMarkIcon,
  CloudDownload,
  ComplyCheckmark,
  Printer,
} from "./micro/icons";

type Props = {
  checkState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export const OfferCard = (props: Props) => {
  const language = useContext(LanguageContext);
  const [isChecked, setIsChecked] = props.checkState;
  const [checkMark1, setCheckMark1] = useState(false);
  const [checkMark2, setCheckMark2] = useState(false);

  const onCheckMark1Click = () => {
    setCheckMark1(!checkMark1);
  };

  const onCheckMark2Click = () => {
    setCheckMark2(!checkMark2);
  };

  useEffect(() => {
    console.log("checkMark1: " + checkMark1);
    console.log("checkMark2: " + checkMark2);
    const checked = checkMark1 && checkMark2;
    console.log("checked: " + checked);
    setIsChecked(checked);
  }, [checkMark1, checkMark2]);

  return (
    <>
      {/* <div className="bg-secondary flex flex-col items-center rounded-3xl min-h-33vh m-14 p-6">
        <div className="w-40 h-40 rounded-full bg-gold flex flex-col justify-center items-center">
          <div className="text-white text-3xl font-bold my-2 pt-4">€4.95</div>
          <div className="text-white text-xl line-through ">€9.99</div>
        </div>
        <div className="mt-4 text-center flex flex-col">
          <div className="mb-6 text-dark text-xl font-semibold">
            Special Offer
          </div>
          <div className="mb-6 text-center justify-center text-dark flex">
            <i className="mr-3">{CloudDownload}</i>
            <span>Digital Download</span>
          </div>
          <div className="mb-6 text-center justify-center text-dark flex">
            <i className="mr-3">{ComplyCheckmark}</i>
            <span>Compliance Guarantee</span>
          </div>
          <div className="mb-6 text-center justify-center text-dark flex">
            <i className="mr-3">{Printer}</i>
            <span>Unlimited Prints@Home</span>
          </div>
        </div>
      </div> */}
      <div className="flex justify-between mb-6 text-grayish">
        {/* Rules checkmark */}
        <div>
          {/* I followed the guidelines for taking the photo, escpecially using a
          white background, natural light from the front, wearing a dark shirt,
          and considering these rules */}
          {language.preview_checked_photo}
        </div>
        <CheckMark onClick={onCheckMark1Click} isCheckMarked={checkMark1} />
      </div>
      <div className="flex justify-between mt-6 mb-6">
        <p className="text-grayish">{language.preview_offer}</p>
        <div className="text-primary mx-3">
          <Badge size={8} />
        </div>
      </div>
      <div className="flex justify-between text-grayish">
        {/* T&C, PP checkmark */}
        <div>
          {language.preview_tc_1}&nbsp;
          <a
            className="underline"
            target="_blank"
            href="https://www.selfideed.com/terms-conditions"
          >
            {language.preview_tc_2}
          </a>
          &nbsp; {language.preview_tc_3}&nbsp;
          <a
            className="underline"
            target="_blank"
            href="https://www.selfideed.com/privacy-policy"
          >
            {language.preview_tc_4}
          </a>
        </div>
        <CheckMark onClick={onCheckMark2Click} isCheckMarked={checkMark2} />
      </div>
    </>
  );
};
