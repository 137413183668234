import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { Upload } from "./micro/icons";

type Props = {
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileRef: React.RefObject<HTMLInputElement>;
};
export const UploadCard = (props: Props) => {
  const language = useContext(LanguageContext);
  const { fileRef, onFileSelect } = props;
  return (
    <>
      <form method="post" encType="multipart/form-data">
        <label htmlFor="file" className="cursor-pointer">
          <div className="bg-secondary border border-dashed border-primary rounded-3xl flex justify-center items-center h-72 w-72">
            <input
              ref={fileRef}
              onChange={onFileSelect}
              type="file"
              name="file"
              id="file"
              className="hidden"
            />
            <div className="flex flex-col items-center">
              <span className="text-primary h-20 mb-6">
                <Upload size={20} />
              </span>
              <h2 className="text-primary font-bold text-2xl">{language.upload_button_text}</h2>
            </div>
          </div>
        </label>
      </form>
    </>
  );
};
