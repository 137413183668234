import React, { useEffect, useState } from "react";
import { FaRegStar } from "react-icons/fa";
import { COUNTRIES, TIMEZONES } from "./services/countryMap";
import { IdSession } from "./IdSession";
import { AuthSession } from "@supabase/supabase-js";
import { Outlet, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Nav } from "./Nav";
import { supabase } from "../utils/auth";
import { ProcessingError } from "./Upload";
import { LanguageContext, SetLanguageContext } from "./contexts/LanguageContext";
import { environment } from "../utils/settings";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { translations } from "./lang/texts";
import { CountryContext } from "./contexts/CountryContext";

export const ErrorsContext = React.createContext<{
  errors: ProcessingError[] | null;
  setErrors: React.Dispatch<React.SetStateAction<ProcessingError[] | null>>;
}>({ errors: null, setErrors: () => null });

export const IdSessionContext = React.createContext<{
  idSession: IdSession | null;
  setIdSession: React.Dispatch<React.SetStateAction<IdSession | null>>;
}>({ idSession: null, setIdSession: () => null });

export const UserSessionContext = React.createContext<{
  userSession: AuthSession | null;
  setUserSession: React.Dispatch<React.SetStateAction<AuthSession | null>>;
}>({ userSession: null, setUserSession: () => null });

export function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [language, setLanguage] = useState(translations.en);
  const [idSession, setIdSession] = useState<IdSession | null>(null);
  const [errors, setErrors] = useState<ProcessingError[] | null>(null);
  const [userSession, setUserSession] = useState<AuthSession | null>(null);
  const [country, setCountry] = useState<string>("");
  const [hasNextButton, setHasNextButton] = useState(false);
  let [urlSearchParams] = useSearchParams();

  function getCountry() {
    let selectedCountry: string | null = localStorage.getItem("selectedCountry");
    if (selectedCountry) {
      console.log("Stored country:", selectedCountry);
      setCountry(selectedCountry);
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone === "" || !timezone) {
      return null;
    }

    const country_new: string = TIMEZONES[timezone]?.c && TIMEZONES[timezone].c[0] || "";
    setCountry(country_new.toLowerCase() || "");
  }

  useEffect(() => {
    getCountry();
    const userLang = navigator.language;
    let lang = userLang.split("-")[0];
    if (lang === "el") {
      lang = "gr";
    }
    const translationKeys = Object.keys(translations);
    if (lang && translationKeys.includes(lang)) {
      console.log("setting language to: ", lang);
      setLanguage(translations[lang]);
    } else {
      setLanguage(translations.en);
    }
    (async function fetchData() {
      const { data, error } = await supabase.auth.getSession();
      console.log("response: ", data);
      if (data.session) {
        setUserSession(data.session);
      } else {
        console.log("no session; error: ", error);
        const docType = urlSearchParams.get("docType");
        if (environment === "demo") {
          navigate("/login" + (docType ? `?docType=${docType}` : ""));
        } else {
          navigate("/login-anon" + (docType ? `?docType=${docType}` : ""));
        }
      }

      console.log("error: ", error);
    })();
  }, []);


  // Function to check if there's a next button on the page
  const checkForNextButton = () => {
    const nextButton = document.querySelector('.next-button');
    setHasNextButton(!!nextButton);
  };

  useEffect(() => {
    // Check for next button on mount and whenever the route changes
    checkForNextButton();

    // Set up a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(checkForNextButton);
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  // Re-check for the next button when the route changes
  useEffect(() => {
    checkForNextButton();
  }, [location]);

  return (
    <>
      <LanguageContext.Provider value={language}>
        <SetLanguageContext.Provider value={setLanguage} >
          <UserSessionContext.Provider value={{ userSession, setUserSession }}>
            <IdSessionContext.Provider value={{ idSession, setIdSession }}>
              <ErrorsContext.Provider value={{ errors, setErrors }}>
                <CountryContext.Provider value={{ country, setCountry }}>
                  <Nav>
                    <Outlet />
                  </Nav>
                  <IdSession
                    idSession={idSession}
                    setIdSession={setIdSession}
                    userId={userSession}
                  />
                </CountryContext.Provider>
              </ErrorsContext.Provider>
            </IdSessionContext.Provider>
          </UserSessionContext.Provider>
        </SetLanguageContext.Provider>
      </LanguageContext.Provider>
    </>
  );
}
