import React, { useContext, useEffect, useState } from "react";
import { SelectionButton } from "./components/SelectionButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { translations } from "./lang/texts";
import { LanguageContext } from "./contexts/LanguageContext";
import { CountrySelected } from "./components/CountrySelected";
import { CountryContext } from "./contexts/CountryContext";

type Option = {
  title: string;
  short: string;
}

type CountryOptions = {
  [key: string]: Option[];
}

export const CountryOptions = () => {
  const navigate = useNavigate();
  const language = useContext(LanguageContext);
  const COUNTRY_OPTIONS: CountryOptions = {
    "ad": [
      { "short": "ad_printed", "title": language.country_options_printed },
    ],
    "al": [
      { "short": "al_printed", "title": language.country_options_printed },
    ],
    "am": [
      { "short": "am_printed", "title": language.country_options_printed },
    ],
    "at": [
      { "short": "at_printed", "title": language.country_options_printed },
    ],
    "au": [
      { "short": "au_printed", "title": language.country_options_printed },
    ],
    "az": [
      { "short": "az_printed", "title": language.country_options_printed },
    ],
    "ba": [
      { "short": "ba_printed", "title": language.country_options_printed },
    ],
    "be": [
      { "short": "be_printed", "title": language.country_options_printed },
    ],
    "bg": [
      { "short": "bg_printed", "title": language.country_options_printed },
    ],
    "br": [
      { "short": "br_printed", "title": language.country_options_printed },
    ],
    "ch": [
      { "short": "ch_printed", "title": language.country_options_printed },
    ],
    "cn": [
      { "short": "cn_printed", "title": language.country_options_printed },
    ],
    "cy": [
      { "short": "cy_printed", "title": language.country_options_printed },
    ],
    "cz": [
      { "short": "cz_printed", "title": language.country_options_printed },
    ],
    "de": [
      { "short": "de_gt10", "title": language.country_options_gt10 },
      { "short": "de_let10", "title": language.country_options_let10 },
    ],
    "dk": [
      { "short": "dk_printed", "title": language.country_options_printed },
    ],
    "ee": [
      { "short": "ee_printed", "title": language.country_options_printed },
    ],
    "es": [
      { "short": "es_printed", "title": language.country_options_printed },
    ],
    "fi": [
      { "short": "fi_printed", "title": language.country_options_printed },
    ],
    "fr": [
      { "short": "fr_printed", "title": language.country_options_printed },
    ],
    "ge": [
      { "short": "ge_printed", "title": language.country_options_printed },
    ],
    "gr": [
      { "short": "gr_printed", "title": language.country_options_printed },
    ],
    "hr": [
      { "short": "hr_printed", "title": language.country_options_printed },
    ],
    "hu": [
      { "short": "hu_printed", "title": language.country_options_printed },
    ],
    "ie": [
      { "short": "ie_printed", "title": language.country_options_printed },
    ],
    "il": [
      { "short": "il_printed", "title": language.country_options_printed },
    ],
    "in": [
      { "short": "in_printed", "title": language.country_options_printed },
      { "short": "in_oci", "title": language.country_options_oci },
    ],
    "is": [
      { "short": "is_printed", "title": language.country_options_printed },
    ],
    "it": [
      { "short": "it_printed", "title": language.country_options_printed },
    ],
    "jp": [
      { "short": "jp_printed", "title": language.country_options_printed },
    ],
    "kr": [
      { "short": "kr_printed", "title": language.country_options_printed },
    ],
    "kz": [
      { "short": "kz_printed", "title": language.country_options_printed },
    ],
    "li": [
      { "short": "li_printed", "title": language.country_options_printed },
    ],
    "lt": [
      { "short": "lt_printed", "title": language.country_options_printed },
    ],
    "lu": [
      { "short": "lu_printed", "title": language.country_options_printed },
    ],
    "lv": [
      { "short": "lv_printed", "title": language.country_options_printed },
    ],
    "mc": [
      { "short": "mc_printed", "title": language.country_options_printed },
    ],
    "md": [
      { "short": "md_printed", "title": language.country_options_printed },
    ],
    "me": [
      { "short": "me_printed", "title": language.country_options_printed },
    ],
    "mk": [
      { "short": "mk_printed", "title": language.country_options_printed },
    ],
    "mt": [
      { "short": "mt_printed", "title": language.country_options_printed },
    ],
    "ng": [
      { "short": "ng_printed", "title": language.country_options_printed },
    ],
    "nl": [
      { "short": "nl_gt10", "title": language.country_options_gt10 },
      { "short": "nl_let10", "title": language.country_options_let10 },
    ],
    "no": [
      { "short": "no_printed", "title": language.country_options_printed },
    ],
    "pl": [
      { "short": "pl_printed", "title": language.country_options_printed },
    ],
    "pt": [
      { "short": "pt_printed", "title": language.country_options_printed },
    ],
    "ro": [
      { "short": "ro_printed", "title": language.country_options_printed },
    ],
    "rs": [
      { "short": "rs_printed", "title": language.country_options_printed },
    ],
    "ru": [
      { "short": "ru_printed", "title": language.country_options_printed },
    ],
    "se": [
      { "short": "se_printed", "title": language.country_options_printed },
    ],
    "si": [
      { "short": "si_printed", "title": language.country_options_printed },
    ],
    "sk": [
      { "short": "sk_printed", "title": language.country_options_printed },
    ],
    "sm": [
      { "short": "sm_printed", "title": language.country_options_printed },
    ],
    "tr": [
      { "short": "tr_printed", "title": language.country_options_printed },
    ],
    "ua": [
      { "short": "ua_printed", "title": language.country_options_printed },
    ],
    "uk": [
      { "short": "uk_gt5", "title": language.country_options_gt5 },
      { "short": "uk_let5", "title": language.country_options_let5 },
    ],
    "us": [
      { "short": "us_printed", "title": language.country_options_printed },
    ],
    "xk": [
      { "short": "xk_printed", "title": language.country_options_printed },
    ],
    "sa": [
      { "short": "sa_printed", "title": language.country_options_printed },
    ],
    "lb": [
      { "short": "lb_printed", "title": language.country_options_printed },
    ],
    "ir": [
      { "short": "ir_printed", "title": language.country_options_printed },
    ],
    "id": [
      { "short": "id_printed", "title": language.country_options_printed },
    ],
    "th": [
      { "short": "th_printed", "title": language.country_options_printed },
    ],
    "ae": [
      { "short": "ae_printed", "title": language.country_options_printed },
    ],
    "jo": [
      { "short": "jo_printed", "title": language.country_options_printed },
    ],



  }

  const [searchParams] = useSearchParams();
  const { country, setCountry } = useContext(CountryContext);
  const [countryOptions, setCountryOptions] = useState<Option[]>([]);
  const [selectedCountry] = useState(searchParams.get("country") || "");

  useEffect(() => {
    console.log("country: ", selectedCountry);
    if (selectedCountry) {
      localStorage.setItem("selectedCountry", selectedCountry);
      setCountry(selectedCountry);
      const options = COUNTRY_OPTIONS[selectedCountry];
      console.log("options: ", options)
      if (!options) {
        navigate("/countries");
        return;
      }
      setCountryOptions(options);
    }
  }, [selectedCountry]);
  return (
    <>
      <div className="flex flex-col w-full items-center justify-center px-6">
        <CountrySelected />
        {
          countryOptions && countryOptions.map((option, index) => (
            <>
              <SelectionButton text={option.title} destination={`/hints/?docType=${option.short}`} />
            </>
          ))
        }
      </div>
    </>
  );
}