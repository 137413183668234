import { AuthSession } from "@supabase/supabase-js";
import React, { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "../utils/auth";
import { IdSessionContext } from "./App";

type Status = {
  id: number;
  title: string;
};

export type IdSession = {
  id: number;
  photo_raw_uri?: string;
  photo_processed_uri?: string;
  photo_final_uri?: string;
  status_id?: number;
  user_id?: string;
  id_type_id?: number;
  country_id?: number;
};

type Props = {
  userId: AuthSession | null;
  idSession: IdSession | null;
  setIdSession: (idSession: IdSession) => void;
};

export function IdSession(props: PropsWithChildren<Props>) {
  const { idSession, setIdSession } = React.useContext(IdSessionContext);
  const { userId } = props;

  const [statuses, setStatuses] = useState<Status[] | null>(null);

  useEffect(() => {
    (async function fetchStatuses() {
      const { data, error } = await supabase
        .from("IdStatus")
        .select("id, title");
      if (error) {
        console.log("error: ", error);
      } else {
        console.log("Statuses data: ", data);
        setStatuses(data);
      }
    })();
  }, []);

  useEffect(() => {
    (async function fetchData() {
      if (idSession || !statuses?.length || !userId) {
        return;
      }

      const { data: session_data, error: session_error } = await supabase
        .from("IdSession")
        .select()
        .eq("user_id", userId.user.id)
        .order("id", { ascending: false });

      if (session_error) {
        console.log("error: ", session_error);
        return;
      }

      if (session_data?.length) {
        const session = session_data[0] as IdSession;
        console.log("Existing id session data: ", session);
        setIdSession(session);
      } else {
        const initialStatus = statuses[0];
        const { data, error } = await supabase
          .from("IdSession")
          .insert([{ status_id: initialStatus.id, user_id: userId.user.id }])
          .select();
        console.log("response: ", data);
        if (error) {
          console.log("error: ", error);
          return;
        }
        setIdSession(data[0] as IdSession);
      }
    })();
  }, [statuses, userId]);

  useEffect(() => {
    if (!idSession?.id) {
      return;
    }

    // (async function writeData() {
    //   const { data, error } = await supabase
    //     .from("IdSession")
    //     .update({ ...idSession })
    //     .eq("id", idSession!.id);
    //   if (error) {
    //     console.log("error: ", error);
    //   } else {
    //     console.log("Id sessionwrite response: ", data);
    //   }
    // })();
  }, [idSession]);

  return <></>;
}
