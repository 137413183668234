import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  let [urlSearchParams] = useSearchParams();

  React.useEffect(() => {
    //   const navigate = useNavigate();
    const docType = urlSearchParams.get("docType");
    // if (docType) {
    //   navigate(`/upload?docType=${docType}`);
    // } else {
    //   navigate(`/upload?docType=notype`);
    // }
    navigate("/landing");
  }, []);

  return <>loading ..</>;
};
