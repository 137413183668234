import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IdSessionContext, UserSessionContext } from "./App";
import { BottomBar } from "./components/BottomBar";
import { OfferCard } from "./components/OfferCard";
import { payment_link } from "../utils/settings";

export function Payment() {
  const { userSession } = React.useContext(UserSessionContext);
  const { idSession } = React.useContext(IdSessionContext);
  const [isChecked, setIsChecked] = React.useState(false);

  const onConfirm = () => {
    if (!idSession?.id) {
      console.log("no user session");
      return;
    }
    location.href = `${payment_link}?client_reference_id=${idSession.id}`;
  };

  return (
    <>
      <div className="mb-28">
        <OfferCard checkState={[isChecked, setIsChecked]} />
        {isChecked && (
          <>
            <div onClick={onConfirm}>
              <BottomBar text="Purchase" />
            </div>
          </>
        )}
      </div>
    </>
  );
}
