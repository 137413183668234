import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { LanguageContext } from '../contexts/LanguageContext';
import { routes } from '../router';

export const Title = () => {
const [pageTitle, setPageTitle] = useState('Home');

  const language = useContext(LanguageContext);
  let curLoc = useLocation();
  useEffect(() => {
    console.log("language: " + language);
    const pathName = curLoc.pathname.split('/')[1].toLowerCase();
    console.log('pathName: ', pathName);
    const route = routes.find(item => item.path.split("/")[0] === pathName);
    if(route && route.title){
      const titleText = language[route.title];
      setPageTitle(titleText);
      document.title = titleText;
    }
  }, [curLoc, language])

    return (
        <div className="mt-24 flex justify-center items-center h-16 px-6">
            <h1 className="text-xl font-bold text-grayish">{pageTitle}</h1>
        </div>
    )
}