import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  text: string;
  destination: string;
  flag?: string;
};
export const SelectionButton = (props: Props) => {
  const navigate = useNavigate();
  let { text, destination, flag } = props;
  
  const onClick = () => {
    navigate(destination);
  }

  return (
    <>
    <div onClick={onClick} className="mb-2 transition-transform ease-out bg-primary flex justify-between w-full h-20 p-6 rounded-3xl items-center">
      {flag && <span className={"fi fi-" + flag}></span>}
      <h1 className="text-2xl font-bold text-white">{text || "Next"}</h1>
    </div>
    </>
  );
};
