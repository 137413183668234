import React, { Dispatch, SetStateAction } from "react";
import {
  CheckMarkIcon,
} from "./micro/icons";

type Props = {
  onClick: () => void;
  isCheckMarked: boolean;
  // setIsCheckMarked: Dispatch<SetStateAction<boolean>>;
};
export const CheckMark = (props: Props) => {
  const { onClick, isCheckMarked } = props;
  return (
    <>
      <div
        onClick={onClick}
        className={(isCheckMarked ? "bg-primary" : "") + " aspect-square w-8 h-8 mr-3 rounded-sm border border-primary bg-secondary flex justify-center items-center"}
      >
        <span className={(isCheckMarked ? "" : "hidden ") + "text-white"}>
          <CheckMarkIcon stroke={3.5} />
        </span>
      </div>
    </>
  )
}