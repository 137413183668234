import React, { useState, useEffect } from 'react';

interface AnimatedHintProps {
    imgSrc: string;
    do_text: string;
    dont_text: string;
}

export const AnimatedHint: React.FC<AnimatedHintProps> = ({ imgSrc, do_text, dont_text }) => {
    const [text, setText] = useState(do_text);
    const [isDoText, setIsDoText] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsDoText((prevIsDoText) => !prevIsDoText);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setText(isDoText ? do_text : dont_text);
    }, [isDoText, do_text, dont_text]);

    return (
        <div className="w-full flex flex-col items-center mb-4">
            <div className="w-full">
                <img src={imgSrc} alt="Animated hint" className="w-full h-auto max-h-[45vh] object-contain" />
            </div>
            <div className="flex justify-center px-4 w-full">
                <p className={`mt-3 text-center text-lg md:text-xl font-bold px-6 py-4 rounded-lg ${isDoText ? 'bg-green-200 text-green-900' : 'bg-red-200 text-red-900'
                    }`}>
                    {text}
                </p>
            </div>
        </div>
    );
};