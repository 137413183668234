import axios, { AxiosResponse } from "axios";
import React, { useContext } from "react";
import {
  useLoaderData,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { supabase } from "../utils/auth";
import { api_host } from "../utils/settings";
import { IdSessionContext, UserSessionContext } from "./App";
import { BigButton } from "./components/BigButton";
import { PhotoView } from "./components/PhotoView";
import { Spinner } from "./components/Spinner";
import { LanguageContext } from "./contexts/LanguageContext";
import { IdSession } from "./IdSession";
import { translations } from "./lang/texts";

import "./style.css";

type Props = {};

export function PhotoResult(props: Props) {
  const language = useContext(LanguageContext);
  const { idSession } = React.useContext(IdSessionContext);
  const { userSession } = React.useContext(UserSessionContext);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  // const [reloading, setReloading] = React.useState(false);
  const [photoUrl, setphotoUrl] = React.useState("");

  React.useEffect(() => {
  //   if (!userSession?.user?.id || !idSession?.id) {
  //     console.log("no user session");
  //     return;
  //   }
  //   (async function get_file() {
  //     let response: AxiosResponse<{ result: string }, any>;
  //     try {
  //       response = await axios.get(
  //         `${api_host}/get_final_image/${userSession.user.id}/${idSession.id}`
  //       );
  //     } catch (error) {
  //       console.log("error: ", error);
  //       setError(`${error.message}: ${error.response?.data?.detail}`);
  //       setLoading(false);
  //       return;
  //     }
  //     if (!response?.data?.result) {
  //       console.log("no result");
  //       setError("No data found");
  //       setLoading(false);
  //       return;
  //     }
  //     console.log("response: ", response);
  //     const photoUriNew = response.data.result;
  //     const [bucket, ...uris] = photoUriNew.split("/");
  //     const uri = uris.join("/");
  //     const { data } = await supabase.storage
  //       .from(bucket)
  //       .createSignedUrl(uri, 3600);
  //     if (!data?.signedUrl) {
  //       console.log("no signed url");
  //       setError("Photo download not possible");
  //       setLoading(false);
  //       return;
  //     }
  //     console.log("signed url: ", data.signedUrl);

  //     setphotoUrl(data.signedUrl);
  //   })();
  }, [userSession, idSession]);

  const imageLoaded = () => {
    setLoading(false);
  };

  const downloadImage = () => {
    if (!photoUrl) {
      console.log("no photo url");
      return;
    }
    const link = document.createElement("a");
    link.href = photoUrl;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-6 w-full">
      {/* <PhotoView photoUri={photoUrl} imageLoaded={imageLoaded} /> */}
      <div className="flex justify-center mt-4">
        <div className="flex flex-col text-center">
          <p className="text-3xl mb-6">📨</p>
          <p className="text-grayish text-xl font-bold">
            {language.result_download_text}
          </p>
        </div>
        {/* <div onClick={downloadImage} className="w-full">
          <BigButton text={language.result_download_button} />
        </div> */}
      </div>
    </div>
  );
}
