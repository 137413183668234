// Not used!
import React, { useContext } from "react";
import { LanguageContext } from "./contexts/LanguageContext";
import { GenericHint } from "./components/GenericHint";

const imgRules = new URL("../img/ID.manual-5.gif", import.meta.url);

export const Hints5 = () => {
    const language = useContext(LanguageContext);

    return (
        <GenericHint
            imgSrc={imgRules.toString()}
            nextRoute="/upload"
            doText={language.hints5_do_text}
            dontText={language.hints5_dont_text}
        />
    );
}