import React from "react";

type Props = {
  photoUri: string;
  imageLoaded?: () => void;
  imgClasses?: string;
};

export const PhotoView = (props: Props) => {
  let { photoUri, imageLoaded, imgClasses } = props;
  imgClasses = imgClasses || "mx-auto max-w-full max-h-42vh md:max-h-50vh";
  return (
    <>
      <img
        className={imgClasses}
        onLoad={imageLoaded || (() => {})}
        src={photoUri}
      />
    </>
  );
};
