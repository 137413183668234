import React from "react";
import { Navbar } from "./components/Navbar";

export function Nav(props) {
  const [back, setBack] = React.useState(0);
  const [backVisible, setBackVisible] = React.useState(false);

  const updateChildrenWithProps = React.Children.map(
    props.children,
    (child, i) => {
      return React.cloneElement(child, {
        back,
        setBack,
        backVisible,
        setBackVisible,
      });
    }
  );

  return (
    <>
      <Navbar></Navbar>
      <div className="">{updateChildrenWithProps}</div>
    </>
  );
}
