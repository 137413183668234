import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomBar } from "./components/BottomBar";
import { LanguageContext } from "./contexts/LanguageContext";
import { StartCard } from "./components/StartCard";
import { CountrySelected } from "./components/CountrySelected";
import { CountryContext } from "./contexts/CountryContext";

const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export const Landing = () => {
    const navigate = useNavigate();
    const language = useContext(LanguageContext);
    const { country } = useContext(CountryContext);
    const [clickCount, setClickCount] = useState(0);
    const [canContinue, setCanContinue] = useState(false);
    const [flickerIndex, setFlickerIndex] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);

    const onNext = () => {
        if (!country) {
            navigate("/countries");
        }
        const supportedCountryList = [
            "de",
            "nl",
            "us",
            "gr",
        ]
        if (!supportedCountryList.includes(country || "")) {
            navigate("/countries");
        } else {
            navigate("/country-options/?country=" + country);
        }
    };

    useEffect(() => {
        console.log("free");
        async function load() {
            await sleep(8500);
            setCanContinue(true);
        }
        load();
    }, []);

    useEffect(() => {
        let flickerInterval: number | null = null;
        if (imageLoaded) {
            flickerInterval = window.setInterval(() => {
                setFlickerIndex((prevIndex) => (prevIndex + 1) % 4);
            }, 2000);
        }

        return () => {
            if (flickerInterval) window.clearInterval(flickerInterval);
        };
    }, [imageLoaded]);

    const onClick = () => {
        setClickCount(clickCount + 1);
        if (canContinue || clickCount > 3) {
            onNext();
        }
    }

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <div className="flex flex-col ">
            <div className="flex-grow flex flex-col items-center justify-center px-6">
                <CountrySelected />
                <div className="flex justify-center w-full">
                    <StartCard flickerIndex={flickerIndex} imageLoaded={imageLoaded} onImageLoad={handleImageLoad} />
                </div>
            </div>
            <div onClick={onClick}>
                <BottomBar text={canContinue ? language.hints_next_button : "........"} inactivate={!canContinue} />
            </div>
        </div>
    )
}