import React, { FormEvent, useContext, useEffect } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { supabase } from "../utils/auth";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { ErrorsContext, IdSessionContext, UserSessionContext } from "./App";
import { IdSession } from "./IdSession";
import { UploadCard } from "./components/UploadCard";
import { InfoCard } from "./components/InfoCard";
import { UploadCardSpinner } from "./components/UploadCardSpinner";
import { api_host } from "../utils/settings";
import { HintList } from "./components/HintList";
import { DoubleArrow } from "./components/micro/icons";
import { CameraView } from "./components/Camera";
import { PhotoView } from "./components/PhotoView";
import { BottomBar } from "./components/BottomBar";
import { MaskPreview } from "./MaskPreview";
import { LanguageContext } from "./contexts/LanguageContext";
import { QRCodeSVG } from 'qrcode.react';

const mask = new URL("../img/mask_black.png", import.meta.url);

const INITIAL_INFO_TEXT = `   
  You can either make a photo or select one from your library. Please click above!
`;

export type ProcessingError = {
  title: string;
  message: string;
  detail: string;
};

type APIResponse = {
  result: string;
  errors: ProcessingError[];
};

export function Upload() {
  const { idSession, setIdSession } = React.useContext(IdSessionContext);
  const { setErrors } = React.useContext(ErrorsContext);
  const { userSession } = React.useContext(UserSessionContext);
  const navigate = useNavigate();
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [localErrors, setLocalError] = React.useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = React.useState("");
  const [urlSearchParams] = useSearchParams();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const language = useContext(LanguageContext);

  const uploadCardRef = React.useRef<HTMLDivElement>(null);

  const userLang = navigator.language;
  const lang = userLang.split("-")[0];

  const onSubmit = () => {
    const files = fileRef.current?.files;
    if (files?.length !== 1) {
      setLocalError("Please select a file");
      return;
    }
    if (!userSession?.user.id || !idSession?.id) {
      setLocalError("Please login");
      return;
    }

    const docType = urlSearchParams.get("docType");
    if (!docType) {
      setLocalError("Please select a document type");
      return;
    }

    setUploadStatus("Processing photo...");

    setLocalError(null);
    const file = files[0];
    console.log("submit:", file.name);

    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Upload progress: ${percentCompleted}%`);
        setUploadProgress(percentCompleted);
      },
      params: {
        user_language: lang,
      },
    };
    (async function upload() {
      let response: AxiosResponse<APIResponse>;
      const sessionId = idSession?.id;
      try {
        response = await axios.postForm(
          `${api_host}/${userSession.user.id}/${sessionId}/photo_upload/${docType}`,
          {
            file,
          },
          config
        );
      } catch (error) {
        console.log("error: ", error);
        const detail = error.response?.data?.detail || "";
        setUploadStatus("");
        const description = language[detail];
        setLocalError(description || detail || "Unknown error");
        return;
      }

      if (response.data.result.length) {
        console.log("response: ", response);
        const errors = response.data.errors;
        setErrors(errors);
        const [bucket, ...uris] = response.data.result.split("/");
        const uri = uris.join("/");
        const { data } = await supabase.storage
          .from(bucket)
          .createSignedUrl(uri, 100000);
        if (!data) {
          setUploadStatus("");
          setLocalError("Error creating photo preview");
          return;
        }
        console.log("data: ", data);
        const photoPath = data.signedUrl;
        // TODO: this does not do anything
        const newIdSession = {
          ...idSession,
          status_id: 4,
          photo_processed_uri: photoPath,
        } as IdSession;
        console.log("newIdSession: ", newIdSession);
        setIdSession(newIdSession);
        // url encode
        const encodedPhotoPaths = encodeURIComponent(photoPath);
        console.log("encodedPhotoPaths: ", encodedPhotoPaths);
        navigate("/preview/" + encodedPhotoPaths);
      } else {
        setUploadStatus("");
        setLocalError("Error during processing");
      }
    })();
  };

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  return (
    <>
      <div className="overflow-auto pt-8 flex justify-center items-center">
        {!isMobile() ? (
          <div className="flex flex-col items-center gap-4">
            <h2 className="text-xl font-semibold">
              Please open this page on your mobile device
            </h2>
            <QRCodeSVG value={window.location.href} size={256} />
            <p className="text-gray-600">
              Scan this QR code with your phone's camera
            </p>
          </div>
        ) : uploadStatus !== "" ? (
          <UploadCardSpinner uploadProgress={uploadProgress} />
        ) : (
          <div className="flex flex-col items-center">
            <div ref={uploadCardRef}>
              <UploadCard onFileSelect={onSubmit} fileRef={fileRef} />
            </div>
            <div className="w-full flex justify-center mt-2 px-14 font-bold text-center">
              <span className="text-red-500">{localErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
