import React, { useContext, useEffect } from "react";
import { SelectionButton } from "./components/SelectionButton";
import { LanguageContext } from "./contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import { count } from "console";
import { Close } from "./components/micro/icons";

type Country = {
  flag: string;
  text: string;
  destination: string;
};


export const Countries = () => {
  const language = useContext(LanguageContext);
  const countries: Country[] = [
    { flag: "ad", text: language.countries_ad, destination: "/country-options/?country=ad" },
    { flag: "al", text: language.countries_al, destination: "/country-options/?country=al" },
    { flag: "am", text: language.countries_am, destination: "/country-options/?country=am" },
    { flag: "at", text: language.countries_at, destination: "/country-options/?country=at" },
    { flag: "au", text: language.countries_au, destination: "/country-options/?country=au" },
    { flag: "az", text: language.countries_az, destination: "/country-options/?country=az" },
    { flag: "ba", text: language.countries_ba, destination: "/country-options/?country=ba" },
    { flag: "be", text: language.countries_be, destination: "/country-options/?country=be" },
    { flag: "bg", text: language.countries_bg, destination: "/country-options/?country=bg" },
    { flag: "br", text: language.countries_br, destination: "/country-options/?country=br" },
    { flag: "ch", text: language.countries_ch, destination: "/country-options/?country=ch" },
    { flag: "cn", text: language.countries_cn, destination: "/country-options/?country=cn" },
    { flag: "cy", text: language.countries_cy, destination: "/country-options/?country=cy" },
    { flag: "cz", text: language.countries_cz, destination: "/country-options/?country=cz" },
    { flag: "de", text: language.countries_de, destination: "/country-options/?country=de" },
    { flag: "dk", text: language.countries_dk, destination: "/country-options/?country=dk" },
    { flag: "ee", text: language.countries_ee, destination: "/country-options/?country=ee" },
    { flag: "es", text: language.countries_es, destination: "/country-options/?country=es" },
    { flag: "fi", text: language.countries_fi, destination: "/country-options/?country=fi" },
    { flag: "fr", text: language.countries_fr, destination: "/country-options/?country=fr" },
    { flag: "gb", text: language.countries_uk, destination: "/country-options/?country=uk" },
    { flag: "ge", text: language.countries_ge, destination: "/country-options/?country=ge" },
    { flag: "gr", text: language.countries_gr, destination: "/country-options/?country=gr" },
    { flag: "hr", text: language.countries_hr, destination: "/country-options/?country=hr" },
    { flag: "hu", text: language.countries_hu, destination: "/country-options/?country=hu" },
    { flag: "ie", text: language.countries_ie, destination: "/country-options/?country=ie" },
    { flag: "id", text: language.countries_id, destination: "/country-options/?country=id" },
    { flag: "in", text: language.countries_in, destination: "/country-options/?country=in" },
    { flag: "ir", text: language.countries_ir, destination: "/country-options/?country=ir" },
    { flag: "is", text: language.countries_is, destination: "/country-options/?country=is" },
    { flag: "it", text: language.countries_it, destination: "/country-options/?country=it" },
    { flag: "jp", text: language.countries_jp, destination: "/country-options/?country=jp" },
    { flag: "kr", text: language.countries_kr, destination: "/country-options/?country=kr" },
    { flag: "kz", text: language.countries_kz, destination: "/country-options/?country=kz" },
    { flag: "lb", text: language.countries_lb, destination: "/country-options/?country=lb" },
    { flag: "li", text: language.countries_li, destination: "/country-options/?country=li" },
    { flag: "lt", text: language.countries_lt, destination: "/country-options/?country=lt" },
    { flag: "lu", text: language.countries_lu, destination: "/country-options/?country=lu" },
    { flag: "lv", text: language.countries_lv, destination: "/country-options/?country=lv" },
    { flag: "mc", text: language.countries_mc, destination: "/country-options/?country=mc" },
    { flag: "md", text: language.countries_md, destination: "/country-options/?country=md" },
    { flag: "me", text: language.countries_me, destination: "/country-options/?country=me" },
    { flag: "mk", text: language.countries_mk, destination: "/country-options/?country=mk" },
    { flag: "mt", text: language.countries_mt, destination: "/country-options/?country=mt" },
    { flag: "ng", text: language.countries_ng, destination: "/country-options/?country=ng" },
    { flag: "nl", text: language.countries_nl, destination: "/country-options/?country=nl" },
    { flag: "no", text: language.countries_no, destination: "/country-options/?country=no" },
    { flag: "pl", text: language.countries_pl, destination: "/country-options/?country=pl" },
    { flag: "pt", text: language.countries_pt, destination: "/country-options/?country=pt" },
    { flag: "ro", text: language.countries_ro, destination: "/country-options/?country=ro" },
    { flag: "rs", text: language.countries_rs, destination: "/country-options/?country=rs" },
    { flag: "ru", text: language.countries_ru, destination: "/country-options/?country=ru" },
    { flag: "sa", text: language.countries_sa, destination: "/country-options/?country=sa" },
    { flag: "se", text: language.countries_se, destination: "/country-options/?country=se" },
    { flag: "si", text: language.countries_si, destination: "/country-options/?country=si" },
    { flag: "sk", text: language.countries_sk, destination: "/country-options/?country=sk" },
    { flag: "sm", text: language.countries_sm, destination: "/country-options/?country=sm" },
    { flag: "th", text: language.countries_th, destination: "/country-options/?country=th" },
    { flag: "tr", text: language.countries_tr, destination: "/country-options/?country=tr" },
    { flag: "ua", text: language.countries_ua, destination: "/country-options/?country=ua" },
    { flag: "us", text: language.countries_us, destination: "/country-options/?country=us" },
    { flag: "xk", text: language.countries_xk, destination: "/country-options/?country=xk" },
    { flag: "il", text: language.countries_il, destination: "/country-options/?country=il" },
    {flag: "ae", text: language.countries_ae, destination: "/country-options/?country=ae" },
    {flag: "jo", text: language.countries_jo, destination: "/country-options/?country=jo" },
  ];
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredCountries, setFilteredCountries] = React.useState(countries);

  useEffect(() => {
    setFilteredCountries(countries.filter((country) => {
      if (searchTerm === "") {
        return country;
      } else if (country.text?.toLowerCase().includes(searchTerm.toLowerCase())) {
        return country;
      }
    }));
  }, [searchTerm]);


  return (
    <>
      <div className="flex flex-col w-full items-center justify-center px-6">
        <div className="h-14 w-full mb-5 sticky z-50 top-28">
          <input
            className="z-10 absolute mb-2 w-full h-14 p-6 rounded-3xl items-center outline-none"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <div className="absolute h-14 w-full flex justify-end items-center pr-5">
              <button onClick={() => setSearchTerm("")} className="z-[20] absolute text-primary w-10 h-10"><span><Close size={10} /></span></button>
            </div>
          )}
          </div>
        {filteredCountries.map(country => (
          <SelectionButton
            key={country.flag}
            flag={country.flag}
            text={country.text}
            destination={country.destination}
          />
        ))}
        {filteredCountries.length === 0 && <p className="text-grayish">No countries found</p>}
      </div>
    </>
  );
}