// React login page
// when one enters username and password, it will be sent to the backend

import React, { useEffect, useState } from "react";

import { supabase } from "../utils/auth";
import { Success } from "./Success";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserSessionContext } from "./App";

type LoginProps = {};

export function Login(props: LoginProps) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [urlSearchParams] = useSearchParams();
  const { userSession, setUserSession } = React.useContext(UserSessionContext);

  useEffect(() => {
    handleSession();
  }, []);

  const handleSession = async () => {
    const { data, error } = await supabase.auth.getSession();
    console.log("response: ", data);
    if (data.session) {
      setUserSession(data.session);
      setLoggedIn(true);
      setTimeout(() => {
        goToLandingPage();
      }, 1000);
    }
  };

  function goToLandingPage() {
    const docType = urlSearchParams.get("docType");
    navigate("/landing" + (docType ? `?docType=${docType}` : ""));
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    supabase.auth
      .signInWithPassword({
        email: username,
        password,
      })
      .then((response) => {
        console.log("response: ", response);
        console.log("message: ", response.error?.message);
        if (response.error) {
          setError(response.error?.message);
        } else {
          const access_token = response.data!.session!.access_token;
          const refresh_token = response.data!.session!.refresh_token;
          console.log("access_token: ", access_token);
          supabase.auth
            .setSession({ access_token, refresh_token })
            .then((response) => {
              console.log("set session: ", response);
              setSuccess("logged in");
              setLoggedIn(true);
              handleSession();
              // goToUpload();
            })
            .catch((error) => {
              console.log("error setting sesssion: ", error);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }

  return (
    <>
      {success && <Success onClose={() => setSuccess("")} />}
      {loggedIn || (
        <div className="flex justify-center">
          <form
            onSubmit={handleSubmit}
            className="bg-white bg-secondary rounded px-8 pt-6 pb-8 mb-4"
          >
            <h2 className="text-xl font-bold text-gray-700 mb-4">Login</h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                Username
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="******************"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              {error && <p className="text-red-500 text-xs italic">{error}</p>}
            </div>
            <div className="flex flex-col items-center ">
              <button
                className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Login
              </button>
              {/*}
              <a
                className="mb-4 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                href="#"
                // onClick={() => props.setViewType(ViewType.Signup)}
              >
                Sign up
              </a>
              <a
                className="mb-4 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                href="#"
              >
                Forgot Password?
              </a> */}
            </div>
          </form>
        </div>
      )}
    </>
  );
}
